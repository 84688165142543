import { Trans } from "@lingui/react/macro";
import { Button } from "@/components/ui/button";
import {
	CardHeader,
	CardTitle,
	ColorFilledCard,
	WhiteCard,
} from "@/components/ui/card";
import { TEMP_TIMING_KEY } from "src/state/react-query/react-query-keys";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { usePostQuestion } from "src/state/react-query/useQuestions";
import {
	useFullNameParticipant,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import { IoSend } from "react-icons/io5";
import { useLingui } from "@lingui/react/macro";
import {
	useAnonSwitch,
	useOpenFullNameDialog,
} from "src/state/zustand/useNameInRoomStore";
import { AddNameDialog } from "../AddNameDialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	FormField,
	FormItem,
	FormControl,
	FormMessage,
	Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { twMerge } from "tailwind-merge";

const questionMaxLength = 2000;

export function CreateQuestion({ room_id }: { room_id: string }) {
	const { t } = useLingui();
	const { setFullNameDialogIsOpen } = useOpenFullNameDialog();
	const { setIsAnon, isAnon } = useAnonSwitch();
	const userInfo = useUserInfoQuery();
	const userId = userInfo.data?.user?.id;
	const { data } = useFullNameParticipant({
		userId,
	});
	const postQuestion = usePostQuestion();
	const hasName = data?.fullName && data?.fullName !== "Anonymous";
	const isQuestionDirectedToEnabled = false;

	const formSchema = z.object({
		addressed_to: z.string().optional(),
		question: z
			.string()
			.min(3, t`Question must at least be 3 characters long.`)
			.max(
				questionMaxLength,
				t`Question can not be longer than 2000 characters.`,
			),
		postAnonymous: z.boolean().default(true),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			addressed_to: undefined,
			question: "",
			postAnonymous: true,
		},
	});

	const isQuestionLengthError = form.formState.errors.question != null;

	async function handlePostQuestion(values: z.infer<typeof formSchema>) {
		if (isQuestionLengthError) {
			return;
		}
		const { addressed_to, question } = values;
		await postQuestion.mutateAsync({
			addressed_to,
			question,
			room_id,
			is_anonymous: isAnon,
		});
		console.time(TEMP_TIMING_KEY);
		form.resetField("question");
		return;
	}

	return (
		<div className="mx-1">
			{userId && !hasName && <AddNameDialog userId={userId} />}
			<ColorFilledCard className="relative mt-6">
				<CardHeader>
					<CardTitle>
						<Trans>Enter your question here!</Trans>
					</CardTitle>
				</CardHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(handlePostQuestion)}
						className="space-y-3 mb-5 mx-3"
					>
						{/* Addressed to */}
						{isQuestionDirectedToEnabled && (
							<FormField
								control={form.control}
								name="addressed_to"
								render={({ field }) => (
									<FormItem className="flex flex-col justify-start items-start">
										<Label className="text-white">
											<Trans>To:</Trans>
										</Label>
										<FormControl className="bg-white">
											<Input
												placeholder={t`Address your question to...`}
												{...field}
												className="md:w-1/2"
											/>
										</FormControl>
										<WhiteCard className="bg-red-100">
											<FormMessage className="text-center my-1" />
										</WhiteCard>
									</FormItem>
								)}
							/>
						)}

						{/* Question */}
						<FormField
							control={form.control}
							name="question"
							render={({ field }) => (
								<FormItem>
									<Label className="text-white">
										<Trans>Question:</Trans>
									</Label>
									<FormControl>
										<Textarea
											placeholder={t`Enter your question...`}
											{...field}
											onChange={field.onChange}
											className={`${
												isQuestionLengthError ? "ring-red-600 ring-3" : ""
											}`}
											data-testid="question-input"
										/>
									</FormControl>
									<p
										className={twMerge(
											"text-xs mt-1 w-fit ml-auto",
											isQuestionLengthError
												? "  text-red-500"
												: "text-gray-200",
										)}
									>
										{field.value.length}/{questionMaxLength}
									</p>
									<WhiteCard className="bg-red-100">
										<FormMessage className="text-center my-1" />
									</WhiteCard>
								</FormItem>
							)}
						/>

						{/* Post anonymous */}
						<div className="flex items-end justify-start float-left">
							<FormField
								control={form.control}
								name="postAnonymous"
								render={() => (
									<FormItem className="flex flex-col justify-start items-start">
										<Label
											className={`text-white ${isAnon ? "font-bold" : ""}`}
										>
											{isAnon ? (
												<Trans>Anonymous</Trans>
											) : (
												<Trans>Not anonymous</Trans>
											)}
										</Label>
										<FormControl>
											<Switch
												checked={isAnon}
												onCheckedChange={() => hasName && setIsAnon(!isAnon)}
												onClick={() => {
													if (isAnon && !hasName && userId) {
														setFullNameDialogIsOpen(true);
													}
													return;
												}}
												data-testid="post-anonymous-switch"
											/>
										</FormControl>
									</FormItem>
								)}
							/>
						</div>

						<div className="flex justify-end">
							<Button
								size="icon"
								type="submit"
								data-testid="post-question-button"
							>
								<IoSend />
							</Button>
						</div>
					</form>
				</Form>
			</ColorFilledCard>
		</div>
	);
}
