import { useEffect, useState } from "react";
import { client } from "../../apiCalls";

type RealTimeData = {
	users: string[];
};

type presenceStates =
	| "SUBSCRIBED"
	| "TIMED_OUT"
	| "CLOSED"
	| "CHANNEL_ERROR"
	| null;

type useUserRoomPresence = {
	userCount: number;
	status: presenceStates;
};
export function useUserRoomPresence({
	roomId,
	userId,
}: {
	roomId: string | undefined;
	userId: string | undefined;
}): useUserRoomPresence {
	const [status, setStatus] = useState<presenceStates>(null);
	const [userCount, setUserCount] = useState<number>(1);
	useEffect(() => {
		if (roomId && userId) {
			const roomChannel = client.channel(roomId);
			roomChannel
				.on("presence", { event: "sync" }, () => {
					const newState = roomChannel.presenceState();
					const numberOfUsers = new Set(Object.keys(newState));
					setUserCount(numberOfUsers.size);
				})
				// .on("presence", { event: "join" }, ({ key, newPresences }) => {
				// })
				// .on("presence", { event: "leave" }, ({ key, leftPresences }) => {
				// })
				.subscribe(async (state) => {
					setStatus(state);
				});
			const set: RealTimeData = { users: [userId] };
			roomChannel.track(set);
			return () => {
				roomChannel.untrack();
			};
		}
	}, [userId, roomId]);
	return { userCount, status };
}
