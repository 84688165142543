import { Trans } from "@lingui/react/macro";
import type { ChannelSubscriptionState } from "./useSubscribeToRoomChannel";
import { Spinner } from "@/components/ui/spinner";
import type { JSX } from "react";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { BiArrowBack } from "react-icons/bi";

type RoomStateHeaderProps = {
	channelState: ChannelSubscriptionState;
	room_number: string;
};
export function RoomStateHeader({
	channelState,
	room_number,
}: RoomStateHeaderProps): JSX.Element {
	if (channelState.state === "JOINING") {
		return (
			<div className="mx-auto w-80 mt-5 text-center text-secondary font-semibold">
				<Trans>Joining room</Trans> {room_number} <Spinner />
			</div>
		);
	}
	if (channelState.state === "DISCONNECTED") {
		return (
			<div>
				<Trans>The server has disconnected room...</Trans> {room_number}{" "}
				<Spinner />
				<div>{channelState.reason}</div>
			</div>
		);
	}
	if (channelState.state === "CHANNEL_ERROR") {
		return (
			<Alert variant="destructive" className="mx-auto w-80 mt-5 text-center">
				<p className="font-semibold">
					<Trans>There was an error while trying to join room </Trans>{" "}
					{room_number}
				</p>
				<div className="mt-3">{`${channelState.error.message}.`}</div>
				{channelState.error.message === "Room does not exist" && (
					<Trans>Check whether you entered the right room number.</Trans>
				)}
				<div className="mt-3">
					<Trans>
						If the error keeps occuring, please contact us at
						wwww.questif.com/contact
					</Trans>
				</div>
				<Button className="mt-3" onClick={() => window.history.go(-1)}>
					<BiArrowBack className="mr-2" /> Go back
				</Button>
			</Alert>
		);
	}
	return <></>;
}
