import * as React from "react";

import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

const cardVariants = cva(
	"max-w-xl w-full rounded-lg mx-auto mt-6 border-2 border-primary bg-white text-slate-950 shadow-xs dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50",
	{
		variants: {
			variant: {
				default: "md:w-10/12",
				inner: "",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

const ColorFilledCard = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(
			"max-w-xl md:w-10/12 w-full rounded-lg mx-auto border border-slate-200 bg-primary text-slate-950 shadow-xs dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50",
			className,
		)}
		{...props}
	/>
));
ColorFilledCard.displayName = "ColorFilledCard";

export type CardProps = React.HTMLAttributes<HTMLDivElement> &
	VariantProps<typeof cardVariants>;

const WhiteCard = React.forwardRef<HTMLDivElement, CardProps>(
	({ className, variant, ...props }, ref) => (
		<div
			ref={ref}
			className={cn(cardVariants({ variant, className }))}
			{...props}
		/>
	),
);
WhiteCard.displayName = "WhiteCard";

const CardHeader = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(" flex flex-col space-y-1.5 p-3", className)}
		{...props}
	/>
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
	<h3
		ref={ref}
		className={cn(
			"text-xl font-sans font-bold text-white leading-none tracking-tight mx-auto",
			className,
		)}
		{...props}
	/>
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
	<p
		ref={ref}
		className={cn("text-sm text-slate-500 dark:text-slate-400", className)}
		{...props}
	/>
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div ref={ref} className={cn("p-3 pt-0 flex", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
	<div
		ref={ref}
		className={cn(" flex items-center p-3 pt-0", className)}
		{...props}
	/>
));
CardFooter.displayName = "CardFooter";

export {
	ColorFilledCard,
	WhiteCard,
	CardHeader,
	CardFooter,
	CardTitle,
	CardDescription,
	CardContent,
};
