import {
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
	WhiteCard,
} from "@/components/ui/card";
import { Trans } from "@lingui/react/macro";
import { UserLogin } from "src/components/Auth/UserLogin";
export const SignInPage = () => {
	return (
		<WhiteCard className=" justify-center">
			<CardHeader className="max-w-md m-auto p-6">
				<CardTitle data-testid="sign-in" className="text-primary">
					<Trans>Sign in</Trans>
				</CardTitle>
				<CardDescription className="text-center">
					<Trans>to find out what else you can do!</Trans>
				</CardDescription>
			</CardHeader>
			<CardContent className="w-full p-6 pt-0">
				<UserLogin />
			</CardContent>
		</WhiteCard>
	);
};
