import { useMutation } from "@tanstack/react-query";
import { questifClient } from "src/apiCalls";

type PostQuestionArgType = {
	question: string;
	room_id: string;
	is_anonymous: boolean;
	addressed_to?: string;
};

export const usePostQuestion = () =>
	useMutation({
		mutationFn: async ({
			question,
			room_id,
			is_anonymous,
			addressed_to,
		}: PostQuestionArgType) => {
			return await questifClient.POST("/v1/auth/room/{room_id}/message", {
				params: { path: { room_id } },
				body: { content: question, is_anonymous, addressed_to },
			});
		},
	});

type PostResponseArgType = {
	reply: string;
	room_id: string;
	is_anonymous: boolean;
	parent_id: string;
};

export const usePostReply = () =>
	useMutation({
		mutationFn: async ({
			reply,
			room_id,
			is_anonymous,
			parent_id,
		}: PostResponseArgType) => {
			return await questifClient.POST("/v1/auth/room/{room_id}/message", {
				params: { path: { room_id } },
				body: { content: reply, is_anonymous, parent_id },
			});
		},
	});

export const useDeleteMessage = () =>
	useMutation({
		mutationFn: async ({ message_id }: { message_id: string }) => {
			return await questifClient.PATCH("/v1/auth/message/{message_id}", {
				params: { path: { message_id } },
				body: { is_archived: true },
			});
		},
	});

export const useEditMessageContent = () =>
	useMutation({
		mutationFn: async ({
			content,
			message_id,
		}: { content: string; message_id: string }) => {
			return await questifClient.PATCH("/v1/auth/message/{message_id}", {
				params: { path: { message_id } },
				body: { content },
			});
		},
	});
