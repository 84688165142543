import { useMutation } from "@tanstack/react-query";
import { questifClient } from "src/apiCalls";
import type { components } from "types/bindings/openapi";

export const useChangeVote = ({
	messageId: questionId,
}: { messageId: string }) => {
	return useMutation({
		mutationFn: async ({ vote }: { vote: components["schemas"]["Vote"] }) => {
			return await questifClient.PUT("/v1/auth/message/{message_id}/vote", {
				params: { path: { message_id: questionId } },
				body: { vote },
			});
		},
	});
};
