import { useMutation } from "@tanstack/react-query";
import { queryClient } from "./client";
import { questionsKey } from "./react-query-keys";
import { markQuestionAsAnswered } from "src/apiCalls";

export const useMarkQuestionAsAnswered = ({
	questionId,
	roomId,
}: { roomId: string; questionId: string }) => {
	return useMutation({
		mutationFn: ({ is_answered }: { is_answered: boolean }) =>
			markQuestionAsAnswered({ is_answered, message_id: questionId }),
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [questionsKey, roomId],
			}),
	});
};
