import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import * as Supabase from "src/apiCalls";
import * as es from "./es.json";
import { useLingui } from "@lingui/react";

const defaultPath = "/";
const redirectTo = `${origin}${defaultPath}`;

export const UserLogin = () => {
	const currentLocale = useLingui();
	const fonts = {
		bodyFontFamily: "Montserrat Variable",
		buttonFontFamily: "Montserrat Variable",
		inputFontFamily: "Montserrat Variable",
		labelFontFamily: "Montserrat Variable",
	};
	return (
		<div className="w-full">
			<Auth
				supabaseClient={Supabase.client}
				appearance={{ theme: ThemeSupa, variables: { default: { fonts } } }}
				providers={["google"]}
				view={"magic_link"}
				redirectTo={redirectTo}
				magicLink
				showLinks={false}
				localization={
					currentLocale.i18n.locale === "es" ? { variables: es } : undefined
				}
			/>
		</div>
	);
};
