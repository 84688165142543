import { useEffect } from "react";
import * as Supabase from "src/apiCalls";
import { useNetworkState } from "react-use";
import { io, type Socket } from "socket.io-client";
import type { UpdateData } from "types/bindings/UpdateData";
import type { Room } from "types/bindings/Room";
import { useImmer } from "use-immer";
import type { Message } from "types/bindings/Message";
import { useAnonymousLogin } from "src/state/react-query/useUserInfoQuery";

export type RoomState = Omit<Room, "messages"> & { messages: MessagesMap };
const JoiningState = {
	state: "JOINING",
} as const;
export type ChannelState = Pick<ChannelSubscriptionState, "state">["state"];
export type ChannelSubscriptionState =
	| {
			state: "OFFLINE";
			roomState?: RoomState;
	  }
	| {
			state: "JOINING";
			roomState?: RoomState;
	  }
	| {
			state: "CONNECTED";
			roomState?: RoomState;
	  }
	| {
			state: "LIVE";
			roomState: RoomState;
	  }
	| {
			state: "CLOSED";
			roomState?: RoomState;
	  }
	| {
			state: "CHANNEL_ERROR";
			roomState?: RoomState;
			error: Error;
	  }
	| {
			state: "DISCONNECTED";
			roomState?: RoomState;
			reason: Socket.DisconnectReason;
	  };
const fullRoomStateChannelName = "full_room_state";
const updateChannelName = "updates";
export type MessagesMap = Map<string, Message>;
export const useSubscribeToRoomChannel = ({
	room_number,
}: { room_number: string | undefined }): {
	channelState: ChannelSubscriptionState;
} => {
	const [channelState, setChannelState] =
		useImmer<ChannelSubscriptionState>(JoiningState);
	const anonymousLogin = useAnonymousLogin();

	const { online } = useNetworkState();
	useEffect(() => {
		if (online === false) {
			setChannelState((draft) => {
				draft.state = "OFFLINE";
			});
		}
	}, [online, setChannelState]);
	useEffect(() => {
		if (room_number && online === true) {
			const socket = io(import.meta.env.VITE_SOCKETIO_URL, {
				auth: async (cb) => {
					if (!(await Supabase.isUserLoggedIn())) {
						await anonymousLogin.mutateAsync();
					}
					const access_token = (await Supabase.client.auth.getSession()).data
						.session?.access_token;
					cb({
						api_key: import.meta.env.VITE_SUPABASE_ANON_KEY,
						access_token,
					});
				},
				query: {
					room_number,
				},
			});
			socket.on(fullRoomStateChannelName, (initialRoomState: Room) => {
				const messages: Map<string, Message> = new Map();
				for (const message of initialRoomState.messages) {
					messages.set(message.id, message);
				}
				const state: ChannelSubscriptionState = {
					state: "LIVE",
					roomState: { ...initialRoomState, messages },
				};
				setChannelState(state);
			});
			socket.on(updateChannelName, (...[updates]: [UpdateData[]]) => {
				console.log("received updates: ", updates);
				setChannelState((draft) => {
					if (draft.state !== "LIVE") {
						return draft;
					}
					for (const update of updates) {
						switch (update.type) {
							case "QuestionUpdate":
							case "QuestionInsert": {
								const upvoted_by_user =
									draft.roomState.messages.get(update.content.id)
										?.upvoted_by_user ?? false;
								draft.roomState.messages.set(update.content.id, {
									...update.content,
									upvoted_by_user,
								});
								break;
							}
							case "QuestionDelete":
								draft.roomState.messages.delete(update.content.id);
								break;
							case "RoomUpdate":
								// biome-ignore lint/style/noParameterAssign: we are using immer
								draft = { ...draft, ...update.content };
								break;
							case "QuestionVoteInsert": {
								const question_id = update.content.question_id;
								const element = draft.roomState.messages.get(question_id);
								if (element === undefined) {
									console.error(
										`Did not find the correct question ${question_id}`,
									);
									return;
								}
								element.upvoted_by_user = true;
								draft.roomState.messages.set(question_id, element);
								break;
							}
							case "QuestionVoteDelete": {
								const question_id = update.content.question_id;
								const element = draft.roomState.messages.get(question_id);
								if (element === undefined) {
									console.error(
										`Did not find the correct question ${question_id}`,
									);
									return;
								}
								element.upvoted_by_user = false;
								draft.roomState.messages.set(question_id, element);
								break;
							}
							default:
								console.error("cannot handle the following update:", update);
						}
					}
				});
			});
			socket.on("connect", () =>
				setChannelState((draft) => {
					draft.state = "CONNECTED";
				}),
			);
			socket.on("disconnect", (reason) =>
				setChannelState((draft) => {
					return { ...draft, state: "DISCONNECTED", error: reason };
				}),
			);
			socket.on("connect_error", (err) =>
				setChannelState((draft) => {
					console.error(err);
					return { ...draft, state: "CHANNEL_ERROR", error: err };
				}),
			);
			socket.onAny((event, ...args) => {
				if (![fullRoomStateChannelName, updateChannelName].includes(event)) {
					console.error(
						`received at unknown channel "${event}", unknown message: ${args}`,
					);
				}
			});
			return () => {
				setChannelState((draft) => {
					draft.state = "CLOSED";
				});
				socket.close();
			};
		}
	}, [room_number, online, setChannelState, anonymousLogin.mutateAsync]);

	useEffect(() => {
		console.log(channelState?.roomState?.messages);
	}, [channelState]);
	return { channelState };
};
