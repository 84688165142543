import { Trans } from "@lingui/react/macro";
import { CreateQuestion } from "./components/Questions/CreateQuestion";
import { QuestionList } from "./components/Questions/QuestionList";
import { useSubscribeToRoomChannel } from "./useSubscribeToRoomChannel";
import { useUserIdQuery } from "src/state/react-query/useUserInfoQuery";
import { useUserRoomPresence } from "./usePresenceDetection";
import { RoomHeader } from "./components/RoomHeader";
import { RoomStateHeader } from "./RoomStateHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import type { JSX } from "react";

export function RoomPage({
	room_number,
}: { room_number: string }): JSX.Element {
	const { channelState } = useSubscribeToRoomChannel({ room_number });
	const { data: userId } = useUserIdQuery();
	const { userCount } = useUserRoomPresence({
		roomId: channelState?.roomState?.room_id,
		userId,
	});
	return (
		<div>
			<RoomStateHeader channelState={channelState} room_number={room_number} />
			{channelState.roomState ? (
				<>
					<RoomHeader
						userCount={userCount}
						roomState={channelState.roomState}
						channelSubscribeState={channelState.state}
					/>
					<>
						<CreateQuestion room_id={channelState.roomState.room_id} />
						<Tabs defaultValue="unanswered" className="mx-1">
							<TabsList className="justify-center flex">
								<TabsTrigger value="unanswered" className="">
									<p className="text-xs md:text-sm">
										<Trans>Unanswered Questions</Trans>
									</p>
								</TabsTrigger>
								<TabsTrigger value="answered" data-testid="answered-tab">
									<p className="text-xs md:text-sm">
										<Trans>Answered Questions</Trans>
									</p>
								</TabsTrigger>
							</TabsList>
							<TabsContent value="unanswered">
								<QuestionList
									messages={Array.from(
										channelState.roomState.messages.values(),
									).filter((question) => question.answered_at === null)}
								/>
							</TabsContent>
							<TabsContent value="answered">
								<QuestionList
									messages={Array.from(
										channelState.roomState.messages.values(),
									).filter((question) => question.answered_at !== null)}
								/>
							</TabsContent>
						</Tabs>
					</>
				</>
			) : null}
		</div>
	);
}
