import { Separator } from "@/components/ui/Separator";
import { OrgList } from "./components/Org/OrgList";
import { RoomsList } from "./components/RoomsList/RoomsList";
import { OrganizationDropdown } from "./components/OrganizationDropdown";

export const isOrgPageEnabled = !import.meta.env.PROD;

export function Index() {
	return (
		<div className="justify-center flex flex-col space-y-8 pt-8">
			<div className="max-w-xl md:w-10/12 w-full mx-auto flex justify-center ">
				{isOrgPageEnabled && (
					<div className="mr-auto ml-1">
						<OrganizationDropdown />
					</div>
				)}
			</div>
			<RoomsList />
			{isOrgPageEnabled && (
				<>
					<div className="flex py-5 items-center max-w-xl w-10/12 m-auto">
						<Separator />
					</div>
					<OrgList />{" "}
				</>
			)}
		</div>
	);
}
