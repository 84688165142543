import { queryClient } from "./client";
import * as Supabase from "src/apiCalls";
import { questionsKey, userInfoKey } from "./react-query-keys";
import { useMutation, useQuery } from "@tanstack/react-query";
import camelcaseKeys from "camelcase-keys";

export const useUserInfoQuery = () =>
	useQuery({
		queryKey: [userInfoKey],
		queryFn: Supabase.getUserInfo,
	});

export const useLogout = () =>
	useMutation({
		mutationFn: async () => await Supabase.client.auth.signOut(),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [userInfoKey] }),
	});

export const useUserIdQuery = () =>
	useQuery({
		queryKey: [userInfoKey],
		queryFn: Supabase.getUserInfo,
		select: (data) => data?.user?.id,
	});

export const useIsAnonymousUser = () =>
	useUserInfoQuery().data?.user?.is_anonymous;

export const useIsLoggedIn = () =>
	useQuery({
		queryKey: [userInfoKey],
		queryFn: Supabase.isUserLoggedIn,
	});
export const useAddFullName = ({ userId }: { userId: string }) =>
	useMutation({
		mutationFn: Supabase.addFullName,
		onSettled: () =>
			queryClient.invalidateQueries({ queryKey: [questionsKey, userId] }),
	});

export const useAnonymousLogin = () =>
	useMutation({
		mutationFn: () => Supabase.client.auth.signInAnonymously(),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [userInfoKey] }),
	});
export const useChangeEmail = () => {
	return useMutation({
		mutationFn: async ({ newEmail }: { newEmail: string }) => {
			const result = await Supabase.client.auth.updateUser({ email: newEmail });
			if (result.error) {
				throw result.error;
			}
			return result.data;
		},
		onSettled: () => queryClient.invalidateQueries({ queryKey: [userInfoKey] }),
	});
};

export const useJoinRoomLoggedInOrAnonymously = () => {
	const anonymousLogin = useAnonymousLogin();
	return useMutation({
		mutationFn: async ({ roomNumber }: { roomNumber: string }) => {
			if (!(await Supabase.isUserLoggedIn())) {
				await anonymousLogin.mutateAsync();
			}
			await Supabase.client.rpc("try_join_room", {
				room_number_in: roomNumber,
			});
		},
	});
};

export const useFullNameParticipant = ({
	userId,
}: { userId: string | undefined }) => {
	return useQuery({
		queryKey: [questionsKey, userId],
		queryFn: async () => {
			if (!userId) {
				return;
			}
			const data = (
				await Supabase.client
					.from("profile")
					.select("full_name")
					.eq("user_id", userId)
					.limit(1)
					.maybeSingle()
					.throwOnError()
			).data;
			if (data === null) {
				return {
					fullName: "Anonymous",
				};
			}
			return camelcaseKeys(data, { deep: true });
		},
		enabled: !!userId,
	});
};
