import { useMutation, useQuery } from "@tanstack/react-query";
import { roomsOfUser } from "./react-query-keys";
import { queryClient } from "./client";
import { questifClient } from "../../apiCalls";
import type { components } from "types/bindings/openapi";

export const useCreateRoom = () => {
	return useMutation({
		mutationFn: async ({ room_name }: { room_name: string }) => {
			return (
				await questifClient.POST("/v1/auth/room", {
					body: { room_name },
				})
			).data?.room_number;
		},
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser],
			}),
	});
};

export type RoomOfUser = {
	archived_at?: string | null;
	created_at: string;
	id: string;
	name: string;
	room_number: components["schemas"]["RoomNumber"];
	user_role: components["schemas"]["RoomRole"];
};

export const useRoomsOfUser = () => {
	return useQuery({
		queryKey: [roomsOfUser],
		queryFn: async () => {
			return (await questifClient.GET("/v1/auth/rooms")).data ?? [];
		},
		staleTime: 10_000,
	});
};

export const useArchiveRooms = ({ room_number }: { room_number: string }) => {
	return useMutation({
		mutationFn: async () => {
			return await questifClient.PATCH("/v1/auth/room/{room_number}", {
				params: { path: { room_number } },
				body: { is_archived: true },
			});
		},
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser],
			}),
	});
};

export const useChangeRoomName = ({ room_number }: { room_number: string }) => {
	return useMutation({
		mutationFn: async ({ name }: { name: string }) => {
			return await questifClient.PATCH("/v1/auth/room/{room_number}", {
				params: { path: { room_number } },
				body: { name },
			});
		},
		onSettled: () =>
			queryClient.invalidateQueries({
				queryKey: [roomsOfUser],
			}),
	});
};
