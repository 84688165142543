import { Trans } from "@lingui/react/macro";
import {
	useIsAnonymousUser,
	useUserInfoQuery,
} from "src/state/react-query/useUserInfoQuery";
import {
	CardContent,
	CardFooter,
	CardHeader,
	WhiteCard,
} from "@/components/ui/card";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { CreateRoomDialog } from "./CreateRoomDialog";
import { UserSettings } from "src/components/TopBar/UserSettings";
import { Alert } from "@/components/ui/alert";
import { useCreateRoomDialog } from "src/state/zustand/createDialogStore";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RoomsCards } from "./RoomsCards";
import type { JSX } from "react";
import { TbCirclePlusFilled } from "react-icons/tb";

export function RoomsList(): JSX.Element {
	const { createRoomDialogIsOpen, setCreateRoomDialogIsOpen } =
		useCreateRoomDialog();
	const userInfo = useUserInfoQuery();
	const isAnonUser = useIsAnonymousUser();
	if (userInfo.isError) return <p>Error!</p>;
	if (userInfo.isPending) return <p>Loading...</p>;
	const userId = userInfo.data.user?.id;
	return (
		<WhiteCard>
			<Tabs defaultValue="all-rooms" className="mx-1">
				<CardHeader className="p-6">
					<TabsList className="grid grid-cols-2">
						<TabsTrigger value="all-rooms" className="text-center">
							<p className="text-sm">
								<Trans>All Rooms</Trans>
							</p>
						</TabsTrigger>
						<TabsTrigger
							value="created"
							className="text-center"
							data-testid="my-rooms"
						>
							<p className="text-sm">
								<Trans>My Rooms</Trans>
							</p>
						</TabsTrigger>
					</TabsList>
				</CardHeader>

				<TabsContent value="all-rooms">
					<CardContent className="pb-6">
						<RoomsCards />
					</CardContent>
				</TabsContent>
				<TabsContent value="created">
					<CardContent>
						<RoomsCards isMyRooms />
					</CardContent>
				</TabsContent>
			</Tabs>
			<CardFooter className="flex flex-col items-center p-6 pt-0">
				{userId && isAnonUser ? (
					<Dialog>
						<DialogTrigger>
							<Alert className="hover:bg-slate-200 cursor-pointer text-center mt-2">
								<Trans>Add your email to create rooms!</Trans>
							</Alert>
						</DialogTrigger>
						<UserSettings
							userId={userId}
							currentEmailUser={userInfo.data.user?.email ?? ""}
							currentFullName={""}
						/>
					</Dialog>
				) : (
					<Dialog
						open={createRoomDialogIsOpen}
						onOpenChange={setCreateRoomDialogIsOpen}
					>
						<DialogTrigger className="flex flex-col align-center justify-center">
							<p className="text-xs text-gray-400">Add a new room! </p>{" "}
							<TbCirclePlusFilled
								className="text-4xl mt-1 text-center mx-auto cursor-pointer text-primary drop-shadow-md hover:text-primary-dark"
								data-testid="open-create-room-dialog"
							/>
						</DialogTrigger>
						<CreateRoomDialog />
					</Dialog>
				)}
			</CardFooter>
		</WhiteCard>
	);
}
