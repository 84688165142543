import { Trans, useLingui } from "@lingui/react/macro";

export const JoinRoomBanner = () => {
	const { t, i18n } = useLingui();

	document.getElementById("room-number")?.addEventListener("input", (e) => {
		const target = e.target as HTMLInputElement;
		let value = target.value.replace(/\D/g, "");

		if (value.length > 8) {
			value = value.substring(0, 8);
		}

		target.setCustomValidity("");

		if (value.length > 0 && value.length < 8) {
			target.setCustomValidity(t`Please enter 8 digits.`);
		}

		if (value.length > 4) {
			value = `${value.substring(0, 4)} ${value.substring(4)}`;
		}

		target.value = value;
	});

	document.getElementById("join-room-form")?.addEventListener("submit", (e) => {
		e.preventDefault();
		const form = e.target as HTMLFormElement;
		const roomNumber = document.getElementById(
			"room-number",
		) as HTMLInputElement;

		if (!form.checkValidity()) {
			form.reportValidity();
			return;
		}

		const formattedRoomNumber = roomNumber.value.replace(/\s/g, "");
		window.location.href = `/room/${formattedRoomNumber}`;
	});

	return (
		<form id="join-room-form">
			<div className="bg-secondary-light py-3 text-center drop-shadow-md">
				<div className="align-center flex justify-center text-secondary">
					<h1
						className={`${i18n.locale === "es" ? "text-sm" : "text-md"} mr-2 mt-1.5 font-bold md:mr-4 md:text-2xl`}
					>
						<Trans>Join a room</Trans>
					</h1>
					<div className="relative">
						<p
							className={`${i18n.locale === "es" ? "text-sm" : "text-md"} absolute ml-2 mt-2 md:text-xl`}
						>
							#
						</p>
						<input
							type="text"
							name="room-number"
							id="room-number"
							className={`${i18n.locale === "es" ? "text-xs" : "text-sm"} bg-white md:w-inherit mr-2 w-fit rounded-md border border-gray-300 py-2 pl-5 focus:border-cyan-400 focus:outline-hidden md:mr-3 md:pl-7 md:text-inherit`}
							placeholder={t`Enter room number`}
							inputMode="numeric"
							data-testid="room-number-input"
							required
						/>
						<button
							type="submit"
							className={`${i18n.locale === "es" ? "text-xs" : "text-sm"} rounded-md bg-linear-to-r from-cyan-600 to-cyan-500 px-3 py-2 font-semibold text-white drop-shadow-sm focus:outline-hidden focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 md:px-7 md:py-2 md:text-xl`}
							data-testid="join-room-button"
						>
							<Trans>Join</Trans>
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};
