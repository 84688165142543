import {
	type PostgrestSingleResponse,
	type User,
	createClient,
} from "@supabase/supabase-js";
import type { Database } from "../types/supabase";
import createQuestifClient, { type Middleware } from "openapi-fetch";
import type { paths } from "../types/bindings/openapi";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

const authAndQueryMiddleware: Middleware = {
	async onRequest({ request }) {
		const accessToken = (await client.auth.getSession()).data?.session
			?.access_token;
		request.headers.set("Authorization", `Bearer ${accessToken}`);
		return request;
	},
	async onResponse({ response }) {
		if (!response.ok) {
			// for more info on the error throwing: https://openapi-ts.dev/openapi-fetch/middleware-auth#throwing
			throw new Error(
				`${response.url}: ${response.status} ${response.statusText}`,
			);
		}
		const { body, ...resOptions } = response;
		return new Response(body, { ...resOptions, status: 200 });
	},
};

export const questifClient = createQuestifClient<paths>({
	baseUrl: import.meta.env.VITE_SOCKETIO_URL,
});
questifClient.use(authAndQueryMiddleware);

if (supabaseAnonKey?.length < 5 || supabaseUrl?.length < 5) {
	throw Error("Missing environment variables");
}
export const client = createClient<Database>(supabaseUrl, supabaseAnonKey, {
	auth: { debug: true },
});

// TODO: Implement the following function and remove this comment.
export async function createOrganisation({
	orgName,
}: { orgName: string }): Promise<string> {
	return orgName;
}

export async function getUserInfo(): Promise<{ user: User | null }> {
	return (await client.auth.getUser()).data;
}
export async function isUserLoggedIn() {
	return (await client.auth.getSession()).data.session != null;
}

export async function addFullName({
	fullName,
	userId,
}: { fullName: string; userId: string }): Promise<
	PostgrestSingleResponse<null>
> {
	return await client
		.from("profile")
		.upsert({ full_name: fullName, user_id: userId })
		.throwOnError();
}

export async function markQuestionAsAnswered({
	is_answered,
	message_id,
}: { is_answered: boolean; message_id: string }) {
	return await questifClient.PATCH("/v1/auth/message/{message_id}", {
		params: { path: { message_id } },
		body: { is_answered },
	});
}
