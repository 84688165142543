import { Trans } from "@lingui/react/macro";
import {
	CardContent,
	CardFooter,
	CardHeader,
	WhiteCard,
} from "@/components/ui/card";
import {
	useFullNameParticipant,
	useUserIdQuery,
} from "src/state/react-query/useUserInfoQuery";
import { MessageOptionsMenu } from "./MessageOptionsMenu";
import { useEffect, useState } from "react";
import { useEditMessageContent } from "src/state/react-query/useQuestions";
import { Button, buttonVariants } from "@/components/ui/button";
import { ErrorMessage } from "src/components/ErrorMessage";
import { Textarea } from "@/components/ui/textarea";
import { shouldShowDebugInformation } from "src/settings";
import { CreateReply } from "../Replies/CreateReply";
import { DisplayReply } from "../Replies/DisplayReply";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Upvote } from "../Upvote";
import { cn } from "@/lib/utils";
import { useLingui } from "@lingui/react/macro";
import type { Message } from "types/bindings/Message";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { useDayjs } from "src/utils/customDayjs";
import { BsReply } from "react-icons/bs";
import { useShowRepliesStore } from "src/state/zustand/replyStore";
import { useRoomsOfUser } from "src/state/react-query/useRooms";

type QuestionProps = {
	message: Message;
	messages: Message[];
};

export function Question({ message, messages }: QuestionProps) {
	const {
		content,
		created_at: createdAt,
		anonymous,
		user_id: userId,
		id: message_id,
		room_id,
	} = message;
	const { t } = useLingui();
	const repliesToCurrentQuestion = messages.filter(
		(r) => r.parent_id === message_id,
	);
	const [parent] = useAutoAnimate({
		duration: 250,
		easing: "ease-in-out",
		disrespectUserMotionPreference: false,
	});
	const [showInput, setShowInput] = useState(false);
	const [newQuestion, setNewQuestion] = useState<string | undefined>(undefined);
	const { showReplies, setShowReplies } = useShowRepliesStore();
	const [showCreateReply, setShowCreateReply] = useState(false);
	const editQuestionContent = useEditMessageContent();
	const { data: currentUserId, isError: isErrorUserId } = useUserIdQuery();
	const { data: name, isError: isErrorFullName } = useFullNameParticipant({
		userId,
	});
	const rooms = useRoomsOfUser().data;
	const currentRoom = rooms?.find((room) => room.id === room_id);
	const isRoomCreator = currentRoom?.user_role === "creator";
	const dayjs = useDayjs((state) => state.dayjs);

	if (isErrorFullName || isErrorUserId)
		return (
			<ErrorMessage
				errorMessage={t`An error has ocurred when loading the name of this contributor.`}
				className="w-1/4 mx-auto"
			/>
		);
	const getNameParticipant = () => {
		if (anonymous) return <Trans>Anonymous</Trans>;
		if (!name) return "...";
		return name.fullName;
	};

	const handleEnter: React.KeyboardEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (e) => {
		if (e.key === "Enter") {
			handleQuestionEdit();
			setShowInput(false);
		}
	};

	const handleQuestionEdit = () => {
		if (newQuestion)
			editQuestionContent.mutate({ content: newQuestion, message_id });
		setShowInput(false);
	};

	useEffect(() => {
		setShowCreateReply(showReplies);
	}, [showReplies]);

	return (
		<div className="mx-1">
			<WhiteCard>
				{shouldShowDebugInformation ? (
					<div className="text-xs">questionId: {message.id}</div>
				) : null}

				<CardHeader>
					{message.answered_at && (
						<div className="flex justify-end">
							<FaCircleCheck className="text-primary text-xl" />
						</div>
					)}
					<div className="flex justify-end">
						<div
							className="text-xs text-gray-500"
							data-testid="posted-by-content"
						>
							<Trans>Posted</Trans> {dayjs.utc(createdAt).fromNow()}{" "}
							<Trans>by</Trans> {getNameParticipant()}
						</div>
					</div>
					{showInput ? (
						<>
							<Textarea
								className="text-black mt-1"
								onChange={(e) => setNewQuestion(e.target.value)}
								defaultValue={content}
								onKeyDown={(e) => handleEnter(e)}
								data-testid="edit-question-input"
							/>
							<div dir="rtl" className="flex">
								<Button
									onClick={() => setShowInput(false)}
									className="flex ml-2"
									size="icon"
									variant="destructive"
								>
									<MdCancel className=" text-xl" />
								</Button>
								<Button
									onClick={handleQuestionEdit}
									size="icon"
									className="flex bg-primary hover:bg-primary-dark"
									data-testid="save-question-edit-button"
								>
									<FaCircleCheck className="text-xl" />
								</Button>
							</div>
						</>
					) : (
						<div className="text-lg" data-testid="question-content">
							{content}
						</div>
					)}
				</CardHeader>
				<CardContent>
					<Collapsible
						className="text-gray-500 text-lg mt-1 w-full"
						open={showReplies}
						onOpenChange={setShowReplies}
					>
						<div className="flex">
							<div className="float-left mr-1">
								<Upvote
									messageId={message.id}
									upvotes={message.upvotes}
									upvoted_by_user={message.upvoted_by_user}
								/>
								{(currentUserId === userId || isRoomCreator) && (
									<MessageOptionsMenu
										setShowInput={setShowInput}
										isRoomCreator={isRoomCreator}
										isMessageAuthor={currentUserId === userId}
										{...message}
									/>
								)}
							</div>

							<Button
								variant={showCreateReply ? "outlinePressed" : "outline"}
								className="md:text-sm text-xs mr-1"
								onClick={() => setShowCreateReply((previous) => !previous)}
								data-testid="show-reply-button"
							>
								<BsReply />
							</Button>

							{repliesToCurrentQuestion &&
								repliesToCurrentQuestion.length > 0 && (
									<CollapsibleTrigger
										className={`${cn(buttonVariants({ variant: showReplies ? "defaultPressed" : "default" }))} mr-1`}
									>
										<p className="mr-1 md:text-sm text-xs">
											{repliesToCurrentQuestion.length} <Trans>replies</Trans>
										</p>

										{showReplies ? (
											<IoIosArrowUp className="mt-1" />
										) : (
											<IoIosArrowDown className="mt-1" />
										)}
									</CollapsibleTrigger>
								)}
						</div>

						<CollapsibleContent className="mt-1">
							<li ref={parent} className="list-none text-start">
								{repliesToCurrentQuestion?.map((reply) => (
									<DisplayReply key={reply.id} reply={reply} />
								))}
							</li>
						</CollapsibleContent>
					</Collapsible>
				</CardContent>
				{showCreateReply && (
					<CardFooter>
						<CreateReply parent_id={message_id} room_id={message.room_id} />
					</CardFooter>
				)}
			</WhiteCard>
		</div>
	);
}
