import { CardContent, ColorFilledCard } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useNavigate } from "@tanstack/react-router";
import { type Dispatch, type JSX, useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { roomRoute } from "src/routes/routes";
import {
	type RoomOfUser,
	useChangeRoomName,
} from "src/state/react-query/useRooms";
import { useDayjs } from "src/utils/customDayjs";
import { Trans } from "@lingui/react/macro";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SlOptions } from "react-icons/sl";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { AiFillEdit } from "react-icons/ai";
import { IoArchive } from "react-icons/io5";
import { ArchivePromptRoom } from "./DeletePromptRoom";

export const MyRoom = ({ ...room }: RoomOfUser) => {
	const { name, room_number, created_at } = room;
	const navigate = useNavigate();
	const useDateFromNow = useDayjs((state) => state.getDateFromNow(created_at));
	const [editRoomName, setEditRoomName] = useState(false);
	const [newName, setNewName] = useState<string | undefined>(undefined);
	const changeRoomName = useChangeRoomName({
		room_number,
	});
	const shortenedName = name.length > 28 ? `${name.slice(0, 28)}...` : name;

	const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			handleChangeRoomName();
		}
	};

	const handleChangeRoomName = () => {
		if (newName) {
			changeRoomName.mutate({ name: newName });
		}
		setEditRoomName(false);
	};

	return (
		<div className="flex items-center justify-center space-x-3">
			<ColorFilledCard
				onClick={() =>
					navigate({
						to: roomRoute.to,
						params: { room_number },
					})
				}
				className="text-white bg-secondary drop-shadow-md hover:bg-secondary-dark cursor-pointer w-80"
			>
				<CardContent className="flex-col p-2">
					{editRoomName ? (
						<div className="flex space-x-2 items-center justify-center py-2">
							<Input
								className="text-black mr-2"
								onChange={(e) => setNewName(e.target.value)}
								defaultValue={name}
								onKeyDown={handleEnter}
							/>

							<FaCircleCheck
								className="cursor-pointer text-primary hover:text-primary-dark drop-shadow-md text-2xl"
								onClick={handleChangeRoomName}
							/>
						</div>
					) : (
						<p className="font-bold text-md">{shortenedName}</p>
					)}
					<div className="flex justify-between md:text-sm text-xs md:flex-row flex-col">
						<p># {room_number}</p>
						<Trans>created </Trans> {useDateFromNow}
					</div>
				</CardContent>
			</ColorFilledCard>
			<RoomOptions {...room} setEditRoomName={setEditRoomName} />
		</div>
	);
};

const RoomOptions = ({
	setEditRoomName,
	...room
}: RoomOfUser & {
	setEditRoomName: Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	return (
		<Dialog>
			<DropdownMenu open={menuIsOpen} onOpenChange={setMenuIsOpen}>
				<DropdownMenuTrigger
					className={`${cn(buttonVariants({ size: "icon", variant: menuIsOpen ? "outlinePressed" : "outline" }))}`}
				>
					<SlOptions className="text-xs" />
				</DropdownMenuTrigger>
				<DropdownMenuContent className="text-gray-500 ">
					<>
						<DialogTrigger>
							<DropdownMenuItem className="hover:bg-gray-200 hover:text-gray-800 cursor-pointer">
								<IoArchive className="mr-1" /> <Trans>Archive</Trans>
							</DropdownMenuItem>
						</DialogTrigger>
						<DropdownMenuItem
							className="hover:bg-gray-200 hover:text-gray-800 cursor-pointer"
							onClick={() => setEditRoomName((previous) => !previous)}
						>
							<AiFillEdit className="mr-1 " /> <Trans>Edit name</Trans>
						</DropdownMenuItem>
					</>
				</DropdownMenuContent>
			</DropdownMenu>
			<ArchivePromptRoom {...room} />
		</Dialog>
	);
};
